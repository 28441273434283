import {getImageBlobUrl, updateStatus} from '../../services/diffEntryService';
import {Link, useLoaderData, useNavigation, useOutletContext} from "react-router-dom";
import './diffEntry.css';
import {acceptanceKind} from "../../models/acceptanceKind";
import {useEffect} from 'react';

export async function loader({params}) {
    let data = {entryId: params.diffEntryId};

    const promises = ["actual", "diff", "baseline"].map(imageName => getImageBlobUrl(data.entryId, imageName));

    const results = await Promise.all(promises);

    data["actual"] = results[0];
    data["diff"] = results[1];
    data["baseline"] = results[2];

    return data;
}

const statusDisplayText = {
    0: "Pending",
    1: "Accepted",
    2: "Rejected",
}

// Preload entries adjacent to the current entry.
const preloadImages = async (diffs, currentIndex) => {
    const totalDiffs = diffs.length;
    const prevIndex = (currentIndex + totalDiffs - 1) % totalDiffs;
    const nextIndex = (currentIndex + 1) % totalDiffs;
    const imagesToPreload = ["actual", "diff", "baseline"];
    
    for (const adjacentIndex of [ prevIndex, nextIndex ]) {
        await Promise.all(
            imagesToPreload.map(imageName => 
                getImageBlobUrl(diffs[adjacentIndex].diffEntryId, imageName)
                    .catch(err => console.warn(`Failed to preload ${imageName} for entry ${adjacentIndex}:`, err))
            )
        );
    }
};

export default function DiffEntry() {
    const data = useLoaderData();
    const job = useOutletContext();
    const navigation = useNavigation();

    const diffs = job.jobData.diffs;
    let currentEntryIndex = diffs.findIndex(diff => diff.diffEntryId === data.entryId);

    useEffect(() => {
        preloadImages(diffs, currentEntryIndex).catch(console.error);
    }, [currentEntryIndex, diffs]);

    const handleStatusUpdate = async (status) => {
        // Optimistically update the UI
        diffs[currentEntryIndex].reviewStatus = status;
        
        // Fire and forget the backend update
        updateStatus(data.entryId, status).catch(error => {
            // If the update fails, revert the optimistic update
            console.error('Failed to update status:', error);
            diffs[currentEntryIndex].reviewStatus = data.reviewStatus;
        });
    }

    const getNextDiffUrl = () => {
        const nextEntryIndex = (currentEntryIndex + 1) % diffs.length;
        const nextEntryId = diffs[nextEntryIndex].diffEntryId;
        return _routeFor(nextEntryId);
    }

    const getPrevDiffUrl = () => {
        const nonNegativeSafeDecrement = diffs.length - 1; // -1 mod imageDirs.length
        const prevEntryIndex = (currentEntryIndex + nonNegativeSafeDecrement) % diffs.length;
        const prevEntryId = diffs[prevEntryIndex].diffEntryId;
        return _routeFor(prevEntryId);
    }

    const _routeFor = (entryId) => {
        return `/apps/${job.clientApp.clientAppId}/jobs/${job.jobId}/${entryId}`;
    }

    return (
        <>
            <div id="overlay" className={navigation.state === "loading" ? "visible" : ""}>
                <div className="loader"/>
            </div>
            <div id="contentContainer">
                <div id="actionBar">
                    <Link id="prev" to={getPrevDiffUrl()}>Prev</Link>
                    <Link id="reject"
                          to={getNextDiffUrl()}
                          onClick={() => handleStatusUpdate(acceptanceKind.Reject)}>Reject</Link>
                    <Link id="accept"
                          to={getNextDiffUrl()}
                          onClick={() => handleStatusUpdate(acceptanceKind.Accept)}>Accept</Link>
                    <Link id="next" to={getNextDiffUrl()}>Next</Link>
                </div>
                <div id="imageContentHeader">
                    <div id="entryIndex">
                        {`Entry ${currentEntryIndex + 1} of ${diffs.length}`}
                    </div>
                    <div id="entryName">
                        {diffs[currentEntryIndex].entryName}
                    </div>
                    <div id="entryStatus">
                        {statusDisplayText[diffs[currentEntryIndex]["reviewStatus"]]}
                    </div>
                </div>
                <div id="imagesContainer">
                    <div className="image-container">
                        <div className="image-detail">Expected</div>
                        <img id="expected" src={data.baseline} alt="baseline"/>
                    </div>

                    <div className="image-container">
                        <div className="image-detail">Diff</div>
                        <img id="diff" src={data.diff} alt="diff"/>
                    </div>

                    <div className="image-container">
                        <div className="image-detail">Actual</div>
                        <img id="actual" src={data.actual} alt="actual"/>
                    </div>
                </div>
            </div>
        </>
    )
}
