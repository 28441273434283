export default function StatusBulletListItem(params) {
    let bulletColor;

    switch(params.color) {
        case "orange":
            bulletColor = 'orange';
            break;
        case "green":
            bulletColor = 'green';
            break;
        case "red":
            bulletColor = 'red';
            break;
        default:
            bulletColor = 'grey';
    }

    const listItemStyle = {
        listStyleType: 'none',
        display: 'grid',
        gridTemplateColumns: '28px minmax(0, 1fr)',  // Fixed width for bullet column
        alignItems: 'center',
        gap: '0',  
        minHeight: '24px',  
    };

    const bulletStyle = {
        display: 'block',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: bulletColor,
        margin: '0 auto',  // Center in column
    };

    const contentStyle = {
        whiteSpace: 'nowrap',  // Prevent wrapping
        width: '100%',  
    };

    return (
        <li className={params.className} style={listItemStyle}>
            <div style={bulletStyle} className="status-bullet" />
            <div style={contentStyle}>
                {params.children}
            </div>
        </li>
    );
}
