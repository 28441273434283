import React, { useRef, useEffect } from "react";
import {Link} from "react-router-dom";
import './job.css'
import StatusBulletListItem from "../../components/statusBulletListItem";

function SidebarContent(params) {
    const statusColorMap = {
        0: "orange", // Pending
        1: "green", // Accepted
        2: "red", // Rejected
    }

    if (params.width <= 20) return null;

    return (
        <ul>
            {params.job.jobData.diffs.map((diff, index) => {
                const isSelected = diff.diffEntryId === params.selectedDiffId;
                return (
                    <StatusBulletListItem 
                        key={index} 
                        color={statusColorMap[diff.reviewStatus] || "grey"}
                        className={isSelected ? "selected" : ""}>
                        <Link to={`${diff.diffEntryId}`}>{diff.entryName}</Link>
                    </StatusBulletListItem>
                )
            })}
        </ul>
    )
}

export default function DiffsSidebar(params) {
    const [width, setWidth] = React.useState(300);
    const sidebarRef = useRef(null);
    const isDragging = useRef(false);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isDragging.current) return;
            
            const newWidth = e.clientX;
            if (newWidth >= 20 && newWidth <= 800) {
                setWidth(newWidth);
            }
        };

        const handleMouseUp = () => {
            isDragging.current = false;
            document.body.style.cursor = 'default';
            document.body.style.userSelect = 'auto';
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    const startResizing = () => {
        isDragging.current = true;
        document.body.style.cursor = 'col-resize';
        document.body.style.userSelect = 'none';
    };

    return (
        <div id="diffsSidebarContainer" ref={sidebarRef} style={{ width: `${width}px` }}>
            <SidebarContent job={params.job} selectedDiffId={params.selectedDiffId} width={width}/>
            <div id="sidebarResizeHandle" onMouseDown={startResizing}/>
        </div>
    )
}
