import {getJob} from '../../services/jobService';
import {Outlet, useLoaderData, useNavigate, useLocation, useParams} from "react-router-dom";
import DiffsSidebar from "./diffsSidebar";
import './job.css';
import {useState, useEffect} from "react";

export async function loader({params}) {
    return await getJob(params.jobId);
}

export default function Job() {
    const initialJob = useLoaderData();
    const [job] = useState(initialJob);
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        // Navigate to first diff if we're at the job root path
        if (location.pathname.endsWith(job.jobId)) {
            const firstDiff = job.jobData.diffs[0];
            if (firstDiff) {
                navigate(`${firstDiff.diffEntryId}`);
            }
        }
    }, [job.jobId, job.jobData.diffs, location.pathname, navigate]);

    return (
        <div id="jobContainer">
            <DiffsSidebar job={job} selectedDiffId={params.diffEntryId}/>
            <div id="jobOutletContainer">
                <Outlet context={job} />
            </div>
        </div>
    );
}
